import React from "react";
import Layout from "components/Layout";
import {PropsWithTranslationSheet} from "../i18n/TranslationSheet";
import {contentfulRenderer} from "../utils/contentfulRenderer";

const TermsAndConditions: React.FC<PropsWithTranslationSheet & {pageContext: {content: any}}> = ({
    pageContext: {t, content}
}) => {
    return (
        <Layout t={t} title={t.TC_TITLE} metaDescription={t.TC_META_DESCRIPTION} noIndex>
            <main className={"prose m-auto pt-10"}>{contentfulRenderer(content)}</main>
        </Layout>
    );
};

export default TermsAndConditions;
